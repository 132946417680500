<template>
  <b-col
    class="hp-profile-menu py-24 px-0"
    style="flex: 0 0 240px"
  >
    <div class="w-100">
      <div class="hp-profile-menu-header mt-16 mt-lg-0 text-center">
        <div class="d-flex justify-content-center">
          <div class="d-inline-block position-relative">
            <div
              class=" d-flex align-items-center justify-content-center rounded-circle"
              style="width: 80px; height: 80px"
            >
              <b-img
                v-if="imagePath"
                :src="imagePath"
                rounded="circle"
                v-bind="mainProps"
              />
              <b-img v-else :src="defaultImage" rounded="circle" v-bind="mainProps" />
            </div>
          </div>
        </div>

        <h3 class="mt-24 mb-4">
          {{ currentDataPendonor.name }}
        </h3>
        <a
          :href="currentDataPendonor.email"
          class="hp-p1-body"
        >
          {{ currentDataPendonor.email }}
        </a>
      </div>
      <div class="hp-profile-menu-body w-100 text-left mt-lg-3">
        <ul>
          <li class="mt-4 mb-16">
            <b-link
              to="/dashboards/user"
              :class="`position-relative text-black-80 hp-text-color-dark-30 hp-hover-text-color-primary-1 hp-hover-text-color-dark-0 py-12 px-24 d-flex align-items-center${
                currentRouteName == 'manage-user-detail' || 'manage-user-edit' ? ' active' : ''
              }`"
            >
              <i class="iconly-Curved-User mr-8" />
              <span>Informasi Personal</span>

              <span
                class="hp-menu-item-line position-absolute h-100 bg-primary hp-bg-dark-0"
                style="width: 2px"
              />
            </b-link>
          </li>
        </ul>
      </div>

    </div>

  </b-col>
</template>

<script>
import {
  BCol,
  BBadge,
  BButton,
  BLink,
  BDropdown,
  BDropdownItem,
  BImg,
} from 'bootstrap-vue'
import PendonorDataService from '../../../../../api/manageuser/manageUserAPI'

export default {
  components: {
    BCol,
    BBadge,
    BButton,
    BLink,
    BDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      path: '',
      currentDataPendonor: null,
      imagePath: null,
      defaultImage: require('@/assets/img/users/user-1.svg'),
      mainProps: { width: 110, height: 80 },
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
  watch: {
    $route() {
      this.getRoute()
    },
  },
  created() {
    this.getRoute()
    this.getPendonor(this.$route.params.id)
  },
  methods: {
    getRoute() {
      this.path = this.$route.path
    },
    getPendonor(id) {
      PendonorDataService.get(id)
        .then(response => {
          this.currentDataPendonor = response.data.data
          this.imagePath = `${process.env.VUE_APP_BASE_URL}${response.data.data.image}`

          if (this.imagePath === null) {
            return this.defaultImage
          }
        })
    },
  },
}
</script>
