<template>
  <b-row>
    <b-col cols="12">
      <b-row align-v="center" align-h="between">
        <b-col cols="12" md="6">
          <h3>Edit Informasi Pendonor</h3>
        </b-col>
        <div class="divider mx-16 border-black-40 hp-border-color-dark-80" />
        <b-col
          cols="12"
          class="hp-profile-content-list d-flex flex-column justify-content-around mt-8 pb-0 pb-sm-120"
        >
          <h3 class="h3 mb-3">Kontak</h3>
          <b-row v-if="currentDataPendonor">
            <b-col cols="6">
              <b-form-group label="Nama Pengguna" label-for="name" class="mb-5">
                <b-form-input
                  id="name"
                  v-model="currentDataPendonor.name"
                  :state="validation.name"
                  placeholder="Masukkan Nama Anda"
                  class="w-full"
                />
                <b-form-invalid-feedback :state="validation.name">
                  {{ fieldErrors.name }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label="Tanggal Lahir"
                label-for="tanggallahir"
                class="mb-5"
              >
                <b-form-input
                  id="tanggallahir"
                  v-model="currentDataPendonor.birth_date"
                  :state="validation.birth_date"
                  placeholder="Masukkan Tanggal Lahir anda"
                  type="date"
                />
                <b-form-invalid-feedback :state="validation.birth_date">
                  {{ fieldErrors.birth_date }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label="No. Telp"
                label-for="nomortelepon"
                class="mb-5"
              >
                <b-form-input
                  id="nomortelepon"
                  v-model="currentDataPendonor.phone"
                  :state="validation.phone"
                  placeholder="Masukkan Nomor Telepon Anda"
                  type="number"
                />
                <b-form-invalid-feedback :state="validation.phone">
                  {{ fieldErrors.phone }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Alamat" label-for="alamat" class="mb-5">
                <b-form-input
                  id="alamat"
                  :state="validation.address"
                  v-model="currentDataPendonor.address"
                  placeholder="Masukkan Alamat Lengkap Anda"
                />
                <b-form-invalid-feedback :state="validation.address">
                  {{ fieldErrors.address }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Gol. Darah" label-for="blood" class="mb-5">
                <b-form-select
                  id="blood"
                  v-model="selected"
                  :value="selected"
                  value-field="value"
                  text-field="text"
                  :state="validation.blood_id"
                  :options="options"
                  class="w-50"
                />

                <b-form-invalid-feedback :state="validation.blood_id">
                  {{ fieldErrors.blood_id }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <div v-else class="flex justify-content-center" style="width: 100%">
            <b-skeleton-table
              :rows="1"
              :columns="6"
              :table-props="{
                bordered: false,
                striped: true,
              }"
            />
          </div>
        </b-col>
      </b-row>
      <b-row
        v-if="currentRouteName == urlmatch"
        class="d-flex justify-content-end"
      >
        <b-button @click="$router.go(-1)" variant="danger-1" class="mx-3">
          Batal
        </b-button>
        <b-button
          :disabled="busy"
          variant="primary-1"
          @click="updatePendonorInfo"
        >
          Simpan
        </b-button>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BSkeletonTable,
} from "bootstrap-vue";
import PendonorDataService from "../../../../../api/manageuser/manageUserAPI";
import KategoriDarahService from "../../../../../api/managebloodcategory/managebloodcatAPI";

export default {
  name: "EditUser",
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormInvalidFeedback,
    BSkeletonTable,
  },
  props: ["urlmatch"],
  data() {
    return {
      selected: "",
      busy: false,
      errors: null,
      currentDataPendonor: null,
      blood: null,
      options: [
        {
          value: null,
          text: "Choose...",
        },
      ],
      fieldErrors: {
        id: undefined,
        name: undefined,
        birth_date: undefined,
        address: undefined,
        phone: undefined,
        blood_id: null,
      },
    };
  },
  mounted() {
    console.log(this.selected);
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    validation() {
      return {
        id: this.fieldErrors.id ? false : null,
        name: this.fieldErrors.name ? false : null,
        birth_date: this.fieldErrors.birth_date ? false : null,
        address: this.fieldErrors.address ? false : null,
        phone: this.fieldErrors.phone ? false : null,
        blood_id: this.fieldErrors.blood_id ? false : null,
      };
    },
  },
  created() {
    this.getAllBloodsCategory();
    this.getPendonor(this.$route.params.id);
    this.updatePendonorInfo();
  },
  watch: {
    "currentDataPendonor.name"(newValue) {
      if (newValue) {
        this.fieldErrors.name = null;
      }
    },
    "currentDataPendonor.birth_date"(newValue) {
      if (newValue) {
        this.fieldErrors.birth_date = null;
      }
    },
    "currentDataPendonor.phone"(newValue) {
      if (newValue) {
        this.fieldErrors.phone = null;
      }
    },
    "currentDataPendonor.address"(newValue) {
      if (newValue) {
        this.fieldErrors.address = null;
      }
    },
  },
  methods: {
    getPendonor(id) {
      PendonorDataService.get(id)
        .then((response) => {
          this.currentDataPendonor = response.data.data;
        })
        .catch((error) => console.error(error));
    },
    updatePendonorInfo() {
      PendonorDataService.update({
        id: this.currentDataPendonor?.id,
        name: this.currentDataPendonor.name,
        birth_date: this.currentDataPendonor.birth_date,
        address: this.currentDataPendonor.address,
        phone: this.currentDataPendonor.phone,
        blood_id: this.selected,
      })
        .then((response) => {
          this.busy = true;

          this.$router.go(-1);

          setTimeout(() => {
            this.busy = false;

            // Show a success toast
            this.$bvToast.toast("Pendonor Berhasil Registrasi", {
              title: "Success",
              variant: "success",
              solid: true,
            });
          }, 500);
        })
        .catch((error) => {
          if (error.response) {
            const errorData = error.response.data;
            if (errorData.code === 400) {
              const errorMessage = errorData.message.split("\\n");

              errorMessage.forEach((msg) => {
                if (msg.includes("id")) {
                  this.fieldErrors.id = msg;
                } else if (msg.includes("name")) {
                  this.fieldErrors.name = msg;
                } else if (msg.includes("birth date")) {
                  this.fieldErrors.birth_date = msg;
                } else if (msg.includes("address")) {
                  this.fieldErrors.address = msg;
                } else if (msg.includes("phone")) {
                  this.fieldErrors.phone = msg;
                } else if (msg.includes("blood id")) {
                  this.fieldErrors.blood_id = msg;
                }
              });

              this.isLoading = false;
            }
          } else {
            // Handle other types of errors (e.g., network issues)
            console.error(error);
          }
        });
    },
    getAllBloodsCategory() {
      KategoriDarahService.getAll()
        .then((response) => {
          const res = response.data.data;

          res.forEach((val) => {
            this.options.push({
              value: val.id,
              text: val.blood_type,
            });
          });

          this.selected = this.options.find(
            (opt) => opt.text === this.currentDataPendonor.blood_name
          );
        })
        .catch((e) => console.error(e));
    },
  },
};
</script>
